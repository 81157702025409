@import '../../styles/variables.scss';

.concreteRings {
  background-color: #efefef;

  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &-item {
    padding: 0 15px 30px;
    width: 33%;

    @media (max-width: $desktop) {
      width: 50%;
    }
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 13px;
    height: 100%;
    background-color: #fff;
  }

  &-img {
    img {
      width: 100%;
    }
  }

  &-title {
    margin-bottom: 10px;
    color: #ec1c24;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  &-context {
    p {
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &-price {
    color: #6eaf2d;
    font-weight: 700;
    span {
      font-weight: 300;
    }
  }
}
