.main-banner {
  position: relative;
  padding: 50px 0;
  background-image: url('../../images/img_03.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  overflow: hidden;
  min-height: 550px;
  height: 100%;

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(3px);
    z-index: -1;
  }

  &__block {
    display: flex;
    @media (max-width: 1000px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__content {
    margin-right: 20px;
    @media (max-width: 1000px) {
      margin: 0 0 20px 0;
    }

    &-action {
      margin-bottom: 40px;

      @media (max-width: 1000px) {
        margin-bottom: 70px;
      }
    }
  }
  &__top {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  &__text {
    h3 {
      @media (max-width: 1000px) {
        font-size: 2.5rem !important;
      }
      @media (max-width: 768px) {
        font-size: 2rem !important;
      }
      span {
        color: #d32f2f;
        font-weight: 500;
      }
    }
  }

  &__list {
    .MuiListItemButton-root {
      padding: 0;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
    .css-cveggr-MuiListItemIcon-root {
      min-width: 35px;
    }
    .MuiSvgIcon-root {
      color: #d32f2f;
    }
  }

  &__form {
    align-self: flex-end;
    &-title {
      margin-bottom: 20px !important;
      line-height: 1.2 !important;
      text-align: center;
    }
    &-inner {
      width: 320px;
      border: 3px solid #fff;
      border-radius: 30px;
      padding: 30px;
      box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.55);
      backdrop-filter: blur(3px);

      @media (max-width: 1000px) {
        width: auto;
      }
      @media (max-width: 768px) {
        padding: 15px;
      }
    }
    &-btn {
      height: 56px;
      width: 100%;
    }

    input {
      width: 100%;
      background-color: #fff;
    }
    .MuiOutlinedInput-root {
      overflow: hidden;
    }
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 20px;
      background-color: #fff;
      position: relative;
    }
    .MuiInputAdornment-root {
      p {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .MuiFormHelperText-root {
      position: absolute;
      bottom: -19px;
      margin: 0;
    }
  }
}

.MuiTouchRipple-root {
  display: none !important;
}

.carousel-item-test {
  display: block !important;
}

.action__title {
  color: #d32f2f;
  font-weight: 500 !important;
  @media (max-width: 1000px) {
    text-align: center;
  }
}

.action__subtitle {
  span {
    display: block;
    @media (max-width: 1000px) {
      display: inline;
    }
  }

  @media (max-width: 1000px) {
    text-align: center;
  }
}

.main-banner__img-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  border: 3px solid #fff;
  border-radius: 30px;
  padding: 7px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(3px);

  img {
    width: 320px;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    width: 300px;

    img {
      width: 280px;
    }
  }
}
.custom-dot-list-style {
  @media (max-width: 1000px) {
    display: none;
  }
}
.main-banner__action-img {
  @media (max-width: 1000px) {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

.container_action {
  @media (max-width: 1000px) {
    height: 100%;
  }
}
