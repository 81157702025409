.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-block {
    width: 24%;
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      width: 48%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
    &.flexStart {
      justify-content: flex-start;
    }
  }
  &-img {
    margin-bottom: 15px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    margin-bottom: 15px;
    text-align: center;
    color: #cc0003;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    @media (max-width: 1000px) {
      font-size: 20px;
    }
  }

  &-price {
    text-align: center;
    color: #001221;
    font-size: 22px;
    @media (max-width: 1000px) {
      font-size: 18px;
    }
  }

  &-content {
    font-weight: 300 !important;
  }
}
