@import '~react-image-lightbox/style.css';

.ourWorks {
  margin-bottom: 50px;

  &-title {
    margin-bottom: 20px !important;
    font-weight: 300 !important;

    @media (max-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &-item {
    width: 24%;
    height: 260px;
    overflow: hidden;
    padding-bottom: 2%;

    @media (max-width: 768px) {
      width: 100%;
    }

    &__three {
      width: 32%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    a {
      display: block;
      height: 100%;
    }
    img {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

.ril__navButtons,
.ril__builtinButton,
.ril__toolbarItemChild {
  outline: 0 !important;
}
