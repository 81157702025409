@import '../../styles/variables.scss';

.cost {
  &-item {
    width: 25%;
    padding: 0 25px;
    position: relative;
    z-index: 1;
    &:first-of-type .cost-block:before {
      display: none;
    }
    p {
      position: absolute;
      bottom: 30px;
      left: 50%;
      color: #455b66;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding: 0 0 10px;
      margin: 0;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  &-row {
    display: flex;
    margin: 0 -25px;
    @media (max-width: $desktop) {
      flex-wrap: wrap;
      margin: 0;
    }
  }
  &-block {
    &::before {
      content: '';
      width: 76px;
      height: 76px;
      position: absolute;
      top: 50%;
      left: -38px;
      transform: translateY(-50%);
      background: url('../../images/decor/ico-plus.png') no-repeat;
      z-index: 2;
    }
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .cost-item p {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .container.cost-wrapper {
    display: block;
  }
  .cost-item {
    width: 100%;
    max-width: 290px;
    margin: 0 auto 38px;
  }
  .cost-block:before {
    left: 50%;
    transform: translate(-50%, 0);
    top: -60px;
  }
  .cost-item p {
    bottom: 40px;
  }
}

@media (max-width: $desktop) {
  .cost {
    padding-bottom: 40px;
  }
  .cost .section-title h2 {
    font-size: 22px;
  }
  .cost-item {
    p {
      bottom: 27px;
    }
    &:nth-of-type(3) {
      .cost-block:before {
        top: -60px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }
  .cost-block:before {
    left: calc(-35%);
    transform: translate(0, -50%);
    top: 50%;
  }
}

@media (max-width: $tablet) {
  .cost-block:before {
    top: -60px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
