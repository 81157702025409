.stepper {
  padding-bottom: 50px;
  &-title {
    padding: 50px 0;
    background: url('../../images/decor/arrow-stepper.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    .MuiContainer-root {
      padding: 20px 0;
    }
  }
}

.steps {
  &-row {
    display: flex;
    margin: 0- 20px;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
  &-item {
    width: 25%;
    padding: 0 20px;
    font-size: 15px;
    line-height: 1.33;
    color: #253238;
    @media (max-width: 1024px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &__img {
      position: relative;
      margin: 0 0 20px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 53px;
        height: 40px;
        background: url('../../images/decor/arrow-decore_r.svg') no-repeat;
      }
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          display: none;
        }
      }
    }
    &__title {
      min-height: 54px;
      margin: 0 0 10px;
      @media (max-width: 768px) {
        min-height: 1px;
      }
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
      color: #15499f;
      position: relative;
      padding: 5px 0 5px 40px;
      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #ffcc00;
        border-radius: 50%;
        font-size: 19px;
        font-weight: 600;
        color: #fff;
      }
    }
    p {
      a {
        font-weight: 600;
        color: #f4415e;
        text-decoration: none;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
