@import '../../styles/variables.scss';

.footer {
  background-color: #071927;
  color: #fff;
  padding: 50px 0;

  &-row {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
  }

  &-col {
    width: 33%;
    padding: 0 15px;

    @media (max-width: $tablet) {
      width: 100%;
    }
    &.center {
      text-align: center;
    }
    p {
      margin-bottom: 15px;
      line-height: 1.2;
      @media (max-width: $tablet) {
        text-align: center;
      }
      strong {
        font-weight: 700;
        padding-right: 2px;
        font-size: 24px;
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover,
        &focus {
          text-decoration: underline;
        }
      }
    }
    address {
      margin-bottom: 15px;
      line-height: 1.2;
    }
  }

  &-social {
    display: flex;
    justify-content: center;
    @media (max-width: $tablet) {
      margin-bottom: 20px;
    }
    .socials {
      gap: 10px;
    }
  }

  &-form {
    @media (max-width: $tablet) {
      max-width: 260px;
      margin: 0 auto;
    }
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 10px;
    }
    .MuiTypography-root {
      margin-bottom: 0;
    }
    .MuiOutlinedInput-root {
      background-color: #fff;
    }
    input {
      width: 100%;
      background-color: #fff;
      border-radius: 30px;
    }
  }
}
