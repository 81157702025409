@import '../../styles/variables.scss';

.confirm {
  background-color: $confirm-color;

  &__content {
    display: flex;
    align-items: center;
    color: #fff;
  }
}
