@import '../../styles/variables.scss';

.variants {
  background-color: #efefef;

  &-row {
    display: flex;
    margin: 0 -15px;
    @media (max-width: $desktop) {
      flex-wrap: wrap;
      margin: 0;
    }
  }

  &-item {
    padding: 0 15px;
    width: 33%;
    margin-bottom: 20px;

    @media (max-width: $desktop) {
      width: 100%;
      padding: 0;
    }
  }

  &-content {
    flex: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-top: -2px;
    padding: 10px 15px;
    text-align: center;
  }

  &-img {
    img {
      width: 100%;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20px;
    background: #fff;
    .decor & {
      background-color: #fee100;
      box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.5);
    }
  }

  &-title {
    margin-bottom: 10px;
    color: $red;
    font-size: 24px;
    font-weight: 500;
  }

  &-text {
    margin-bottom: 10px;
    color: #000;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Roboto';
  }

  &-price {
    margin-bottom: 20px;
    font-size: 30px;
    color: #6eaf2d;
    font-weight: 300;
    span {
      font-weight: 700;
      padding-right: 7px;
    }
  }

  button {
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    border: none;
    background-color: $red;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    &:hover,
    &:focus {
      background: lighten($red, 10%);
    }
  }
}
