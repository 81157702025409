.inner-modal {
  margin-bottom: 15px;
  text-align: center;
  &-img {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  h6 {
    margin-bottom: 10px;
  }
  p {
    font-weight: 300;
    font-size: 14px;
  }
}
