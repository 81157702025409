.green {
  color: rgb(110, 175, 45);
}
.red {
  color: rgb(236, 28, 36);
}
.upperCase {
  text-transform: uppercase;
}

.semiBold {
  font-weight: 500;
}

.textCenter {
  text-align: center;
}

.block-padding {
  padding: 50px 0 70px;
}

@media (max-width: 768px) {
  .mainTitle {
    font-size: 1.5rem !important;
  }
}

.mainTitle {
  color: #025173;
  font-weight: 500;
}
