.modal {
  overflow-y: auto;
  max-width: 400px;
  width: 100% !important;
  max-height: 90vh;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 15px !important;
    max-width: 300px;
  }

  h2 {
    margin-bottom: 30px;
    text-align: center;
    font-weight: 500 !important;
    font-size: 1.4rem !important;
  }

  &-form {
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &-link {
    margin-top: 10px;
    text-align: center;
    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
