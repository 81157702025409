.socials {
  display: flex;
  align-items: center;
  gap: 5px;
  a {
    display: flex;
    justify-content: center;
  }
  img {
    width: 22px;
  }
}
