@import '../../styles/variables.scss';

.navbar-top {
  position: fixed;
  z-index: 1100;
  width: 100%;
  background-color: $header-top !important;
  @media (max-width: 901px) {
    position: relative;
  }
  &__toolbar {
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px;
    @media (max-width: 899px) {
      display: none !important;
    }
  }

  &__link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    @media (max-width: 1000px) {
      font-size: 11px !important;
    }
  }
}

.navbar-bottom {
  padding: 10px 0;
  padding-top: 76px;
  background-color: #fff !important;
  @media (max-width: 900px) {
    padding-top: 10px;
  }

  &__toolbar {
    justify-content: space-between;
    padding: 0 10px;
  }
  &__logotype {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 35px;
      height: 100%;
    }
    h1 {
      font-size: 22px;
      color: #246581;
      font-weight: 700;
    }
  }
  &__description {
    margin-left: 15px;
    p {
      color: #333;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.mobile-menu {
  padding: 15px;
  padding: 15px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &_navigation {
    display: flex;
    flex-direction: column;
    align-items: center;

    .navbar-top__link {
      font-size: 16px !important;
    }
  }

  .phones {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      font-size: 16px;
    }
  }

  .socials {
    justify-content: center;
  }

  &__container {
    .MuiPaper-root {
      width: 90%;
    }
  }

  .callback-btn {
    align-self: center;
  }
}
