@import '../../styles/variables.scss';

.constructions {
  &-container {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;
    @media (max-width: $desktop) {
      flex-direction: column;
      align-items: center;
    }
  }
  &-item {
    width: 50%;
    padding: 0 20px;
    @media (max-width: $desktop) {
      padding-bottom: 110px;
      width: 100%;
    }
    @media (max-width: $tablet) {
      width: 100%;
      padding-bottom: 50px;
    }

    &__inner {
      position: relative;
    }
  }

  &-img {
    margin-bottom: -3px;
    img {
      width: 100%;
    }
  }

  &-content {
    padding: 15px;
    background: linear-gradient(180deg, #ffeda1 0%, #ffe469 83.44%);

    h4 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      color: #15499f;
    }
  }

  &-description {
    background: #ffe46b;
    padding: 15px 15px 30px;
    position: relative;

    &__inner {
      text-align: left;

      h5 {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: #15499f;
      }
      P {
        font-size: 16px;
      }

      .open & {
        height: auto;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -35px;
      box-sizing: border-box;
      left: 0;
      width: 100%;
      height: 0;
      border-top: 35px solid #ffe46b;
      border-left: 270px solid transparent;
      border-right: 270px solid transparent;
      z-index: 1;
      @media (max-width: 1160px) {
        display: none;
      }
    }

    &__inner {
      transition: max-height 1s;
      overflow: hidden;
      max-height: 0;

      .open & {
        max-height: 300px;
      }
    }
  }

  &-btn {
    z-index: 3;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);

    button {
      position: relative;
      font-size: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #d62d30;
      border: 3px solid #fff;
      cursor: pointer;

      &:before {
        position: absolute;
        content: '';
        width: 24px;
        height: 5px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
      }
      &:after {
        position: absolute;
        content: '';
        width: 5px;
        height: 24px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;

        .open & {
          display: none;
        }
      }
    }
  }
}
