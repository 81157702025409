.our-partners {
  &_item {
    padding: 0 10px;
    img {
      width: 100%;
    }
  }
}

.react-multi-carousel-item {
  align-items: center;
  justify-content: center;
  display: flex;
}
