@import '../../styles/variables.scss';

.price {
  &-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 55px;
  }
  &-item {
    width: 25%;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
    transition-duration: 0.3s;
    transition-property: transform;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    &::before {
      pointer-events: none;
      position: absolute;
      z-index: -1;
      content: '';
      top: 100%;
      left: 5%;
      height: 10px;
      width: 90%;
      opacity: 0;
      background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0) 80%
      );

      transition-duration: 0.3s;
      transition-property: transform, opacity;
    }

    &:hover {
      transform: translateY(-20px);
      &:before {
        opacity: 1;
        transform: translateY(5px);
      }
    }
    @media (max-width: $desktop) {
      width: 50%;
    }
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &-main {
    max-width: 170px;
    height: 100%;
    padding-bottom: 30px;
    img {
      width: 100%;
    }
  }

  &-default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 5px;
    padding: 20px 27px;
    background-color: #fff;
    box-shadow: -1px -2px 10px -2px rgba(34, 60, 80, 0.2);
    border: 2px solid #d4d4d4;
  }

  &-header {
    color: #253238;
    text-decoration: none;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.1;
    font-weight: normal;
    font-size: 16px;
  }

  &-footer {
    color: #253238;
    font-size: 22px;
    width: 100%;
    z-index: 2;
    strong {
      color: #f4415e;
      font-weight: 700;
    }
    span {
      display: block;
      margin-bottom: 15px;
      text-align: center;
    }
    .btn {
      width: 100%;
      display: block;
    }
  }

  &-decor {
    background: #fee100;
    border-radius: 5px;
    padding: 0;
    position: relative;
    top: -30px;
    .price-header {
      text-align: center;
      font-size: 22px;
      color: #15499f;
      font-weight: 600;
      z-index: 3;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px 20px;
      overflow: hidden;
      height: 100%;
    }
  }
}

.new_img_transform {
  position: relative;
}

.top_y_parallelogram {
  width: 270px;
  height: 207px;
  transform: skew(-20deg) rotate(-20deg) translateY(0px);
  position: absolute;
  right: -79px;
  top: -53px;
  z-index: 2;
  background: #fff;
  @media (max-width: $desktop) {
    display: none;
  }
}
// .top_y_parallelogram:after {
//   content: '';
//   display: block;
//   background: #fff;
//   width: 370px;
//   height: 200px;
//   position: absolute;
//   top: 10px;
//   right: -65px;
//   z-index: 2;
// }
.center_w_parallelogram img {
  position: relative;
  top: -20px;
}
.center_w_parallelogram {
  position: relative;
  z-index: 5;
  margin-bottom: 0px;
}

@media (max-width: $desktop) {
  .price .container {
    flex-wrap: wrap;
  }
  .price-item {
    width: 50%;
    margin-bottom: 30px;
  }
  .price-decor {
    top: 0;
    padding: 0 15px;
    background-color: transparent;
  }
  .price-decor__inner {
    height: auto;
    background: #fee100;
  }
}
@media (max-width: $tablet) {
  .price .container {
    justify-content: center;
  }
  .price-item {
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
  }
}

.btn {
  max-width: 200px;
  background: #d20001;
  font-size: 24px;
  padding: 12px 24px;
  color: #fff !important;
  border-radius: 10px;
  border: 1px solid #d20001;
  margin: 0px auto;
  text-decoration: none;
  text-align: center;
  transition: 0.3s linear;

  &:hover,
  &:focus {
    background: lighten(#d20001, 10%);
  }
}
