.withImage {
  position: relative;

  &:after {
    content: '?';
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 210px;
    line-height: 1;
    font-weight: 700;
    color: #e9e9e9;
    z-index: -1;
  }
}
